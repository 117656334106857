// const baseURL = process.env.REACT_APP_API_URL;
//  Stagging
export const baseURL = "https://devapi.caafimaadplus.org/api";

// productionnpm
 //export const baseURL = "https://api.caafimaadplus.org/api";
 

// Demo
//export const baseURL = "https://devapi.ogowhealthportal.com/api";


//local
 //export const baseURL = "https://localhost:44361/api";

export const API_PATH = {
  AUTH_API: {
    LOGIN_URL: `${baseURL}/account/getToken`,
    ME_URL: `${baseURL}/user/get`,
    REQUEST_PASSWORD_URL: `${baseURL}/Account/ForgotPassword`,
    CHANGE_PASSWORD_URL: `${baseURL}/Account/ResetPassword`,
    REGISTER_URL: `${baseURL}/api/auth/register`,
    LOGOUT_URL: `${baseURL}/Account/Logout`,
    LOGIN_WITH_2FA: `${baseURL}/account/LoginWith2FA`,
  },
  GENERAL_DASHBOARD: {
    getGenericDashboardStats: `${baseURL}/RegisterDashboard`,
    getGenericGlobalDashboardStats: `${baseURL}/GlobalDashboard`,
    getStats: `${baseURL}/GeneralDashboard/GetStats`,
    getHistoryChart: `${baseURL}/GeneralDashboard/GetHistoryGraph`,
    getSectionCellListing: `${baseURL}/GlobalDashboardList`,
    getSectionGraphListing: `${baseURL}/GlobalDashboardGraph`,

    getCaseProfiles: `${baseURL}/GeneralDashboard/GetCaseProfile`,
    getSmsNudges: `${baseURL}/GeneralDashboard/GetSMSNudges`,
    getIVRListing: `${baseURL}/GeneralDashboard/GetIVRs`,
    getNotifyVisitors: `${baseURL}/GeneralDashboard/GetAllNotifyVisitors`,
    getAllCount: `${baseURL}/GeneralDashboard/GetAllCount`,
    getHistoryExcel: `${baseURL}/GeneralDashboard/GetHistoryExcel`,
    getStatsExcel: `${baseURL}/GeneralDashboard/GetStatsExcel`,
    getNotifyUserExcel: `${baseURL}/GeneralDashboard/GetAllNotifyVisitorsExport`,
    getIVRExcel: `${baseURL}/GeneralDashboard/ExportIVRs`,
    sendManualMessage: `${baseURL}/GeneralDashboard/SendManualMessage`,
  },
  IMMUNIZATION_DASHBOARD: {
    getStats: `${baseURL}/ImmunizationDashboard/GetStats`,
    getImmunizationChart: `${baseURL}/ImmunizationDashboard/GetImmunizationsChart`,
    getCompletedImmunization: `${baseURL}/ImmunizationDashboard/GetCompletedImmunizations`,
    getRefusedAnotherFacilityImmunizations: `${baseURL}/ImmunizationDashboard/GetRefusedAnotherFacilityImmunizations`,
    getRefusedAnotherFacilityGraphImmunizations: `${baseURL}/ImmunizationDashboard/GetRefusedAnotherFacilityImmunizationsGraph`,
    getLookups: `${baseURL}/GeneralDashboard/GetLookups`,
    getIncompletedImmunization: `${baseURL}/ImmunizationDashboard/GetIncompleteImmunizations`,
    getMissedImmunization: `${baseURL}/ImmunizationDashboard/GetMissedImmunizations`,
    getChildImmunizationDetails: `${baseURL}/ImmunizationDashboard/GetChildImmunizationsDetails`,
    getDeleteImmunizationRequests: `${baseURL}/Immunization/GetImmunizationDeleteRequests`,
    approveOrDenyImmunizationDeleteRequest: `${baseURL}/Immunization/ApproveOrDenyImmunizationDeleteRequest`,
    bulkApproveOrDenyImmunizationDeleteRequest: `${baseURL}/Immunization/BulkApproveOrDenyImmunizationDeleteRequest`,
    // getPatientVaccinations: `${baseURL}/ImmunizationDashboard/GetMotherVaccinations`,
    exportStatsExcel: `${baseURL}/ImmunizationDashboard/GetStatsExcel`,
    exportMissedImmunizationExcel: `${baseURL}/ImmunizationDashboard/GetMissedImmunizationsExcel`,
    exportChildImmunizationsExcel: `${baseURL}/ImmunizationDashboard/GetChildImmunizationsDetailsExcel`,
    // exportPatientVaccinationExcel: `${baseURL}/ImmunizationDashboard/GetMotherImmunizationsDetailsExcel`,
  },
  ANC_DASHBOARD: {
    getStats: `${baseURL}/ANCDashboard/GetStats`,
    getVaccinationChart: `${baseURL}/ANCDashboard/GetVaccinationsChart`,
    getCompletedVaccination: `${baseURL}/ANCDashboard/GetCompletedVaccinations`,
    getIncompletedVaccination: `${baseURL}/ANCDashboard/GetIncompleteVaccinations`,
    getMissedVaccination: `${baseURL}/ANCDashboard/GetMissedVaccinstions`,
    getMotherVaccination: `${baseURL}/ANCDashboard/GetMotherVaccinations`,
    getMotherANCDetails: `${baseURL}/Antenatal/GetMotherANCDetails`,
    exportAncStatsExcel: `${baseURL}/ANCDashboard/GetStatsExcel`,
    exportMissedVaccinationExcel: `${baseURL}/ANCDashboard/GetMissedVaccinationsExcel`,
    exportMotherVaccinationExcel: `${baseURL}/ANCDashboard/GetMotherVaccinationsExcel`,
  },
  CLINICS: {
    getAllCLinicsList: `${baseURL}/Clinic/GetAll`,
    getAllMappingClinicsList: `${baseURL}/Clinic/GetAllMappingClinicsList`,
    addNewCLinic: `${baseURL}/Clinic/Post`,
    exportClinic: `${baseURL}`,
    getSingleCLinic: `${baseURL}/Clinic/GetClinicById`,
    getTags: `${baseURL}/Tags/GetAll`,
    adminTallyValidation: `${baseURL}/TallyValidation/adminTallyValidation`,
  },
  ADMIN: {
    getAllAdminList: `${baseURL}/Admin/GetAll`,
    getSingleAdmin: `${baseURL}/Admin/GetAdminById`,
    setAdminActive: `${baseURL}/Admin/SetActiveAdmin`,
    addEditAdmin: `${baseURL}/Admin/Save`,
    getAdminClinicList: `${baseURL}/Admin/GetClinicsByAdmin`,
    assignClinics: `${baseURL}/Admin/AssignClinics`,
  },
  PATIENT: {
    getPatientList: `${baseURL}/Patient/GetAll`,
    isReminderEnabled: `${baseURL}/Patient/ToggleReminders`,
    getSinglePatient: `${baseURL}/Patient/Get`,
    getMotherWithAncData: `${baseURL}/Patient/GetMothersWithANCData`,
    changeGender: `${baseURL}/Patient/ChangeGender`,
  },
  LEARNING_CENTRE: {
    addVideo: `${baseURL}/VideoLearning/AddVideo`,
    getVideos: `${baseURL}/VideoLearning/GetVideos`,
    deleteVideo: `${baseURL}/VideoLearning/DeleteVideo`,
    getLanguageList: `${baseURL}/Language/GetAll`,
  },
  CARE_GIVER: {
    getCareGiverList: `${baseURL}/Caregiver/GetAll`,
    getSingleCareGiver: `${baseURL}/Caregiver/GetCaregiverById`,
    addCaregiver: `${baseURL}/Patient/Add`,
    updateCaregiver: `${baseURL}/Patient/Update`,
    getDoctorByClinic: `${baseURL}/Doctor/GetAll`,
  },
  CHILDS: {
    getChildsList: `${baseURL}/Child/GetAll`,
    getChild: `${baseURL}/Caregiver/GetCaregiverById`,
    getChildEdit: `${baseURL}/Child/GetEdit`,
    updateChild: `${baseURL}/Child/Update`,
  },
  CHILD_IMMUNIZATION: {
    getPendingVaccine: `${baseURL}/Immunization/GetPendingChildVaccines`,
    addImmunization: `${baseURL}/Immunization/Add`,
    updateImmunization: `${baseURL}/Immunization/Update`,
    getRecordedImmunizations: `${baseURL}/Immunization/GetRecordedImmunizations`,
    getSingleImmunization: `${baseURL}/Immunization/GetImmunizationById`,
  },

  CHILD_OBSERVATION: {
    addChildObservation: `${baseURL}/Observation/Add`,
    updateChildObservation: `${baseURL}/Observation/Update`,
    getChildObservation: `${baseURL}/Observation/GetByPatient`,
    getSingleChildObservation: `${baseURL}/Observation/Get`,
  },
  CHILD_PRESCRIPTION: {
    addChildPrescription: `${baseURL}/MedicalPrescriptions/Add`,
    updateChildPrescription: `${baseURL}/MedicalPrescriptions/Update`,
    getChildPrescription: `${baseURL}/MedicalPrescriptions/GetByPatient`,
    getSingleChildPrescription: `${baseURL}/MedicalPrescriptions/GetById`,
  },
  DOCTOR: {
    getAllDoctorList: `${baseURL}/Doctor/GetAll`,
    getSingleDoctor: `${baseURL}/Doctor/GetDoctorById`,
    setDoctorActive: `${baseURL}/Admin/SetActiveDoctor`,
    addEditDoctor: `${baseURL}/Admin/Save`,
  },

  GENERIC: {
    activateUser: `${baseURL}/admin/User/Activate`,
    deactivateUser: `${baseURL}/admin/User/Deactivate`,
    getAllUsersExceptPatient: `${baseURL}/Admin/GetAllLoginUser`,
  },
  SCHEDULE: {
    getDoctorScheduleList: `${baseURL}/Schedule/GetByDoctor`,
    addSchedule: `${baseURL}/Schedule/Add`,
    editSchedule: `${baseURL}/Schedule/Update`,
    getSingleScheduleDetail: `${baseURL}/Schedule/Get`,
  },
  LOGGING_ACTIVITY: {
    getLoggingActivityList: `${baseURL}/LoggingActivity/GetAll/`,
    getLoggingActivityAnalytics: `${baseURL}/LoggingActivity/Analytics`,
    getAllTallyValidation: `${baseURL}/TallyValidation/GetAllDashboard`,
    getLoggingActivityExport: `${baseURL}/LoggingActivity/AnalyticsExcel`,
    getLoggingActivityChart: `${baseURL}/LoggingActivity/AnalyticsChart`,
  },
  DHIS_DATASET_CONFIGURATION: {
    addDatasetConfiguration: `${baseURL}/DHIS_DataSetMappings/Add`,
    updateDatasetConfiguration: `${baseURL}/DHIS_DataSetMappings/Update`,
    deleteDatasetConfiguration: `${baseURL}/DHIS_DataSetMappings/Delete`,
    getDatasetConfiguration: `${baseURL}/DHIS_DataSetMappings/GetDataSetMappings`,
    getDataSetMappingSelectList: `${baseURL}/DHIS_DataSetMappings/GetDataSetMappingsSelectList`,
  },
  DHIS_DATAELEMENT_CONFIGURATION: {
    addDataelementConfiguration: `${baseURL}/DHIS_DataElementMapping/Add`,
    updateDataelementConfiguration: `${baseURL}/DHIS_DataElementMapping/Update`,
    deleteDataelementConfiguration: `${baseURL}/DHIS_DataElementMapping/Delete`,
    getDataelementConfiguration: `${baseURL}/DHIS_DataElementMapping/GetDataElementMappings`,
    getDataElementMappingSelectList: `${baseURL}/DHIS_DataElementMapping/GetDataElementMappingsSelectList`,
  },
  DHIS_DATASET_SETUP: {
    addDataSetSetup: `${baseURL}/DHIS_DataElementToDataTableMapping/Add`,
    updateDataSetSetup: `${baseURL}/DHIS_DataElementToDataTableMapping/Update`,
    deleteDataSetSetup: `${baseURL}/DHIS_DataElementToDataTableMapping/Delete`,
    getDataSetSetupList: `${baseURL}/DHIS_DataElementToDataTableMapping/GetDataElementsToDataTableMappings`,

    getDatabaseLabelList: `${baseURL}/DHIS_DataElementToDataTableMapping/GetDataLabels`,
    CheckDHISPushRequest: `${baseURL}/DHIS_DataPush/CheckDHISPushRequest`,
  },

  DHIS_FACILITY_CONFIGURATION: {
    addFacilityConfiguration: `${baseURL}/DHIS_FacilityMappings/Add`,
    updateFacilityConfiguration: `${baseURL}/DHIS_FacilityMappings/Update`,
    deleteFacilityConfiguration: `${baseURL}/DHIS_FacilityMappings/Delete`,
    getFacilityConfigurationMappingList: `${baseURL}/DHIS_FacilityMappings/GetFacilityMappings`,

    addDHISFacility: `${baseURL}/DHIS_FacilityMappings/AddFacility`,
    updateDHISFacility: `${baseURL}/DHIS_FacilityMappings/Update`,
    getByIdDHISFacility: `${baseURL}/DHIS_FacilityMappings/GetById`,
    deleteDHISFacility: `${baseURL}/DHIS_FacilityMappings/Delete`,
    getDHISFacilitiesListing: `${baseURL}/DHIS_FacilityMappings/GetDHISFacilitiesListing`,
  },

  DHIS_DATA_UPLOAD: {
    getDhisDataUpload: `${baseURL}/DHIS_DataPush/GetView`,
    pushDhisDataUpload: `${baseURL}/DHIS_DataPush/Push`,
    dhisLogin: `${baseURL}/DHIS_DataPush/DhisLogin`,
    getDhisPushRequests: `${baseURL}/DHIS_DataPush/GetPushRequests`,
    genrateDhisPushRequests: `${baseURL}/DHIS_DataPush/GeneratePushRequest`,
    getDhisLogs: `${baseURL}/DHIS_DataPush/GetDHISLogs`,
  },
  LOCATION: {
    getLocation: `${baseURL}/Locations/GetLocations`,
    getAllLocation: `${baseURL}/Locations/GetAllLocations`,
    getAllDoctorDesignation: `${baseURL}/Admin/GetAllDesignation`,
    addLocation: `${baseURL}/Locations/SaveLocations`,
  },
  PERMISSIONS: {
    getAllPolicies: `${baseURL}/Policies/GetAllPolicies`,
    getAllPolicyGroups: `${baseURL}/Policies/GetAllPolicyGroups`,
    getAllRoles: `${baseURL}/Policies/GetAllRoles`,
    getAllPolicyGroupsByGroupId: `${baseURL}/Policies/GetAllPolicyGroups`,
    addOrUpdatePolicyGroup: `${baseURL}/policies/AddOrUpdatePolicyGroup`,
    assignPolicies: `${baseURL}/policies/AssignPolicies`,
    getDHIS2Creds: `${baseURL}/DHIS_DataSetMappings/GetDHIS2Creds`,
    updateDHIS2Creds: `${baseURL}/DHIS_DataSetMappings/UpdateDHIS2Creds`,
    getAdministeredLocationTypes: `${baseURL}/Immunization/GetAdministeredLocationTypes`,
    addPolicyGroup: `${baseURL}/Policies/AddPolicyGroup`,
    deleteRole: `${baseURL}/policies/DeletePolicyGroup`,
  },
};
